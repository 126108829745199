<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

defineProps<{
  currentResort: object
}>()

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const mobileMenu = ref(false)

const bookingWidgetStore = useBookingWidgetStore()
const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

function bookNowClickOnSlideOut() {
  bookingWidgetStore.toggleBookingModal('nav')
  mobileMenu.value = false
}

const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'en')
})

const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'es')
})

const currentLanguage = computed(() => {
  if (currentPage?.language_code === 'es')
    return 'Español'

  return 'English'
})

onMounted(() => {
  const handleClick = (event) => {
    const link = event.target.closest('a')

    if (link && link.getAttribute('href').includes('#book-now')) {
      event.preventDefault()
      bookingWidgetStore.toggleBookingModal('nav')
    }
  }

  document.addEventListener('click', handleClick)

  // Clean up when the component is unmounted
  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClick)
  })
})
</script>

<template>
  <div id="book-now" class="bg-abs-black fixed top-0 left-0 w-full z-100">
    <div class="relative container mx-auto flex space-x-4 xl:justify-between">
      <div class="py-4 flex items-center space-x-8 ">
        <button class="xl:hidden" @click="mobileMenu = !mobileMenu">
          <Icon name="heroicons:bars-3-20-solid" class="text-white w-8 h-8" />
        </button>
        <a href="/" aria-label="Homepage">
          <LogosKimpton class="w-[150px] lg:w-[250px] invert" />
        </a>
      </div>

      <div class="hidden xl:flex">
        <div>
          <ul class="flex flex-row justify-end items-center pt-2 text-xs pr-12 text-right">
            <li class="mr-4">
              <a class="text-grey-dark hover:underline" target="_blank" href="https://www.ihg.com/onerewards/content/us/en/offers/onerewards">IGH<span class="text-2xs pb-2">&reg;</span> ONE REWARDS</a>
            </li>
            <li>
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 font-thin text-sm text-white shadow-sm">
                    {{ currentLanguage }}
                    <Icon name="heroicons:chevron-down-20-solid" class="-mr-1 h-5 w-5 text-gray-400" />
                  </MenuButton>
                </div>

                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a :href="enLink?.path" class="block px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']">English</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a :href="esLink?.path" class="block px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']">Español</a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </li>
          </ul>
          <ul v-if="currentResort?.menu" class="flex pt-4 pb-4 pr-12 space-x-4">
            <li v-for="item in currentResort.menu.menu_items" :key="item.name" class="text-white uppercase font-light text-sm">
              <a :href="item.permalink" class="text-white">{{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div class="relative h-full">
          <button class="bg-kimpton-action h-full text-white px-8 uppercase font-bold" @click="bookNowClickOnSlideOut">
            Book Now
          </button>
          <div class="down-triangle" />
        </div>
      </div>
    </div>
    <div
      v-if="showBookingWidget"

      class="relative px-8 pt-4 z-50 flex justify-center inline-flight-booking w-full bg-abs-black"
    >
      <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="bookNowClickOnSlideOut">
        Close
      </button>

      <FlightBookingForm />
    </div>

    <div
      class="absolute h-screen z-50 inset-0 bg-white p-12 duration-500"
      :class="{
        '-translate-x-full': !mobileMenu,
        'translate-x-0': mobileMenu,
      }"
    >
      <div class="flex justify-between items-start">
        <LogosKimpton class="w-[200px]" />
        <button @click="mobileMenu = !mobileMenu">
          <Icon name="heroicons:x-mark-20-solid" class="text-black w-12 h-12" />
        </button>
      </div>

      <button class="mt-4 bg-kimpton-action text-white px-8 py-4 uppercase font-bold" @click="bookNowClickOnSlideOut">
        Book Now
      </button>

      <div class="mt-12 flex flex-col space-y-12">
        <ul class="flex flex-col space-y-8">
          <li v-for="item in currentResort?.menu.menu_items" :key="item.name" class="text-white uppercase font-bold text-lg">
            <a :href="item.permalink" class="text-black">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .down-triangle {
    border-left: 73px solid transparent;
    border-right: 73px solid transparent;
    border-top: 20px solid #df6548;

    @apply absolute z-100 -bottom-[20px] left-0 w-full;
  }
</style>
